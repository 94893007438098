import { Section } from "./utils"

const sections: Section[] = [
  {
    link: "/comite-regional/equipe/",
    name: "Le Comité Régional",
    index: true,
  },
  {
    link: "/comite-regional/commissions/",
    name: "Les commissions",
    index: false,
  },
  // {
  //   link: "/comite-regional/relance/",
  //   name: "Plan de relance 2023",
  //   index: false,
  // },
  {
    link: "/comite-regional/aides/",
    name: "Aides financières",
    index: false,
  },
  {
    link: "/comite-regional/demarches/",
    name: "Démarches et procédures",
    index: false,
  },
  {
    link: "/comite-regional/licences/",
    name: "Licences",
    index: false,
  },
  {
    link: "/comite-regional/pv-ag/",
    name: "PV d'AG",
    index: false,
  },
  {
    link: "/comite-regional/statuts/",
    name: "Statuts et Règlement intérieur",
    index: false,
  },
]

export default sections
